const courses = [
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-1.png",
    image2x: "/images/content/card-pic-1@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-2.png",
    image2x: "/images/content/card-pic-2@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-3.png",
    image2x: "/images/content/card-pic-3@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-4.png",
    image2x: "/images/content/card-pic-4@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
];

const socials = [
  {
    type: "facebook",
    url: "https://facebook.com",
  },
  {
    type: "instagram",
    url: "https://instagram.com",
  },
  {
    type: "twitter",
    url: "https://twitter.com/CorsiBritt",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/brittcorsi/",
  },
];

export const companyList = [
  {
    title: "Yoga",
    courses: [
      {
        name: "Britt Corsi",
        image: "/images/company/britt.png",
        position: "Executive Officer, Founder",
        courses: courses,
        socials: [
          {
            type: "twitter",
            url: "https://twitter.com/CorsiBritt",
          },
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/brittcorsi/",
          },
        ],
      },
      {
        name: "Drew Stelzer",
        image: "/images/company/drew.png",
        position: "Board",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/drew-stelzer-54749177/",
          },
        ],
      },
      {
        name: "Nate Kelly",
        image: "/images/company/nate.jpg",
        position: "Board",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/nkelly/",
          },
        ],
      },
      {
        name: "Amy McCawley",
        image: "/images/company/amy.png",
        position: "Board",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/amy-mccawley-70aa116/",
          },
        ],
      },
      {
        name: "Tom Tyrrell",
        image: "/images/company/tom.png",
        position: "Board",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/tom-tyrrell-79a9681b/",
          },
        ],
      },
      {
        name: "Karen Allport",
        image: "/images/company/karen.png",
        position: "Advisor",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/kallport/",
          },
        ],
      },
      {
        name: "Dave Nash",
        image: "/images/company/dave.png",
        position: "Advisor",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/dave-nash-95a46/",
          },
        ],
      },
      {
        name: "Jason Manasse",
        image: "/images/company/jason-manasse.png",
        position: "Advisor",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/jmanasse/",
          },
        ],
      },
      {
        name: "Cameron Rout",
        image: "/images/company/cameron.png",
        position: "Advisor",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/cameronrout/",
          },
        ],
      },
      {
        name: "Dana Sudindranath",
        image: "/images/company/danan.png",
        position: "Advisor",
        courses: courses,
        socials: [
          {
            type: "linkedin",
            url: "https://www.linkedin.com/in/danan-sudindranath-969502b/",
          },
        ],
      },
    ],
  },
  {
    title: "Gym & Fitness",
    courses: [
      {
        name: "Marcelino Hettinger",
        image: "/images/content/avatar-3.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Rosemarie Hintz",
        image: "/images/content/avatar-4.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Syble Prohaska",
        image: "/images/content/avatar-5.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Ila Reinger",
        image: "/images/content/avatar-6.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
    ],
  },
  {
    title: "Gymnastics",
    courses: [
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Marcelino Hettinger",
        image: "/images/content/avatar-3.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Rosemarie Hintz",
        image: "/images/content/avatar-4.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Syble Prohaska",
        image: "/images/content/avatar-5.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Ila Reinger",
        image: "/images/content/avatar-6.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
    ],
  },
  {
    title: "Mindfulness",
    courses: [
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Marcelino Hettinger",
        image: "/images/content/avatar-3.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Rosemarie Hintz",
        image: "/images/content/avatar-4.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Syble Prohaska",
        image: "/images/content/avatar-5.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Ila Reinger",
        image: "/images/content/avatar-6.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
    ],
  },
];

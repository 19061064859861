import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../components/Image";
import styles from "./Hero.module.sass";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          {/* <div className={cn("stage", styles.stage)}>
            Access 30K+ Incentives. Connect with Suppliers.
          </div> */}
          <h1 className={cn("h1", styles.title)}>
            Unlock Sustainability Solutions In One Marketplace.
          </h1>
          <div className={styles.text}>
            Start, execute, and manage your climate & sustainability journey to
            go green in days - not years.
          </div>
          <div className={styles.btns}>
            <Link
              className={cn("button", styles.button)}
              style={{ backgroundColor: "#21a0fe" }}
              to="https://marketplace.earthabc.com"
            >
              Start Now
            </Link>
            <Link
              className={cn("button-stroke", styles.button)}
              to="/contact-us"
            >
              Contact Us
            </Link>
          </div>
        </div>
        {/* <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          className={styles.scroll}
        /> */}
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <img
              srcSet="/images/hydroelectric-energy.png"
              src="/images/hydroelectric-energy.png"
              alt="Hydroelectric Energy"
            />
          </div>
          <div className={styles.preview}>
            <img
              srcSet="/images/eco-system.png"
              src="/images/eco-system.png"
              alt="Ecosystem"
            />
          </div>
          <div className={styles.preview}>
            <Image
              srcSet="/images/hydroelectric-power@0.png"
              src="/images/hydroelectric-power@0.png"
              srcSetDark="/images/hydroelectric-power@0.png"
              srcDark="/images/hydroelectric-power@0.png"
              alt="Wind Energy"
            />
          </div>
          <div className={styles.preview}>
            <img
              srcSet="/images/hydroelectric-power@0.png"
              src="/images/hydroelectric-power@0.png"
              alt="Carbon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import cn from "classnames";
import React from "react";
import ScrollParallax from "../../../components/ScrollParallax";
import styles from "./Steps.module.sass";

const items = [
  {
    title: "Discover Incentives",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "Easily find climate and sustainability incentives that align with your business goals, thanks to our comprehensive platform.",
  },
  {
    title: "Match Expert Grant Writers",
    color: "#9757D7",
    images: "/images/content/whistle.svg",
    content:
      "We match you with experienced grant writers based on the incentives you request to assist you in preparing and submitting your incentive applications, ensuring a higher chance of success.",
  },
  {
    title: "Connect Seekers and Suppliers",
    color: "#EF466F",
    images: "/images/content/medal.svg",
    content:
      "We act as the bridge between seekers and suppliers, facilitating meaningful connections and partnerships for a greener, more sustainable future.",
  },
  {
    title: "Streamline Solutions",
    color: "#45B26B",
    images: "/images/content/stopwatch.svg",
    content:
      "We streamline the entire process, making it effortless for your company to embrace sustainability and act as a driving force for positive change.",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
            EarthABC simplifies accessing sustainability funds with curated
            incentives and expert grant writing, offering comprehensive
            features, reporting, and seamless third-party integration -
            streamlining every part of the process.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;

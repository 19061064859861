import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import ScrollParallax from "../ScrollParallax";
import styles from "./Intro.module.sass";

const Intro = ({ scrollToRef }) => {
  return (
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/data-analysis-graph.png"
              src="/images/content/data-analysis-graph.png"
              alt="Gloves"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/carbon.png"
              src="/images/carbon.png"
              alt="Bag"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/truck@0.svg"
              src="/images/truck@0.svg"
              alt="Bottle"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h1", styles.title)}>
            Access the largest climate and sustainability supplier database.
          </h2>
          <div className={styles.text}>
            EarthABC saves you days, weeks, and even months of time wasted
            trying to find the right solutions. Less searching, more progress.
          </div>
          <Link
            className={cn("button", styles.button)}
            style={{ backgroundColor: "#21a0fe" }}
            to="https://marketplace.earthabc.com"
          >
            Start Saving Time
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Intro;

export const TermsOfService = () => {
  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: 150,
        paddingRight: 150,
        paddingBottom: 20,
      }}
    >
      <h1>EARTHABC TERMS OF SERVICE</h1>
      <p>Effective: January 22, 2023 | Country version: United States</p>
      <p>
        Understanding our Terms of Service and the Services we offer is
        important because, by accessing our website and using our Services,
        you’re agreeing to these terms.
      </p>
      <br />
      <h2>Terms</h2>
      <br />
      <p>
        Service provider: EarthABC services are provided by, and you’re
        contracting with:
        <br />
        EarthABC INC.
        <br />
        Organized under the laws of the State of Delaware, USA, and operating
        under the laws of the USA
        <br />
        PO BOX 444 brunswick, Ohio 44212, USA
      </p>
      <br />
      <p>
        Your relationship with EarthABC
        <br />
        These terms help define the relationship between you and EarthABC.
        broadly speaking, we give you permission to use our services if you
        agree to follow these terms, which reflect how EarthABC’s business
        works. When we speak of “EarthABC,” “we,” “us,” and “our,” we mean
        EarthABC and its affiliates.
      </p>
      <br />
      <p>
        What you can expect from us
        <br />
        <br />
        We provide a broad range of services that are subject to these terms,
        including:
      </p>
      <ul>
        <li>
          • apps and sites (like The Climate Network and its incentive and
          ecosystem search)
        </li>
        <li>• platforms (like The Climate Network)</li>
        <li>
          • integrated services (like modeling the built environment embedded in
          our platforms)
        </li>
        <li>
          • consultancy (like leveraging our expertise and network for your
          benefit)
        </li>
      </ul>
      <p>
        Some of these services include content that you can interact with.
        <br />
        Our services are designed to work together, making it easier for you to
        move from one activity to the next. For example, if you are interested
        in an incentive, you can match with the appropriate process or
        contractor to complete it.
      </p>
      <br />
      <p>
        Develop, improve, and update EarthABC services
        <br />
        We’re constantly developing new technologies and features to improve our
        services. For example, we are preparing to use artificial intelligence
        and machine learning to provide you with faster answers and guidance. As
        part of this continual improvement, we sometimes add or remove features
        and functionalities, increase or decrease limits to our services, and
        start offering new services or stop offering old ones. Our services can
        be achieved through our platform at this time and do not require you to
        download software. In the future, this may change.
      </p>
      <p>
        If we make material changes that negatively impact your use of our
        services or if we stop offering a service, we’ll provide you with
        reasonable advance notice, except in urgent situations such as
        preventing abuse, responding to legal requirements, or addressing
        security and operability issues.
      </p>
      <br />
      <p>
        <b>What we expect from you Follow these terms</b>
        <br />
        The permission we give you to use our services continues if you comply
        with these terms.
        <br />
        You also agree that our privacy policy applies to your use of our
        services.
        <br />
        Although we give you permission to use our services, we retain any
        intellectual property rights we have in the services.
      </p>
      <br />
      <p>
        <b>Respect others</b>
        <br />
        We want to maintain a respectful environment for everyone, which means
        you must follow these basic rules of conduct:
      </p>
      <ul>
        <li>
          • don’t abuse, harm, interfere with, or disrupt the services — for
          example, by accessing or using them in fraudulent or deceptive ways,
          introducing malware, or spamming, hacking, or bypassing our systems or
          protective measures. Permission to use your content
        </li>
      </ul>
      <p>
        Some of our platform features are designed to let you upload, submit,
        store, send, receive, or share your business information. If you choose
        to upload or share information, please make sure you have the necessary
        rights to do so and that the content is lawful.
      </p>
      <p>
        <b>License</b>
        <br />
        Your information remains yours, which means that you retain any
        intellectual property rights that you have in your information.
        <br />
        We need your permission if your intellectual property rights restrict
        our use of your information. You provide EarthABC with that permission
        through this license.
      </p>
      <p>
        <b>What’s covered</b>
      </p>
      <p>
        This license covers your information if that information is protected by
        intellectual property rights.
        <br />
        What’s not covered
      </p>
      <ul>
        <li>
          • This license doesn’t affect your privacy rights — it’s only about
          your intellectual property rights
        </li>
        <li>• This license doesn’t cover information that is:</li>
        <li>
          • publicly-available factual information that doesn’t require a
          license because it’s considered common knowledge that everyone’s free
          to use.
        </li>
        <li>
          • feedback that you offer, such as suggestions to improve our
          services.
        </li>
      </ul>
      <br />
      <p>
        <b>Scope</b>
        <br />
        This license is:
      </p>
      <ul>
        <li>• worldwide, which means it’s valid anywhere in the world</li>
        <li>
          • non-exclusive, which means you can license your content to others
        </li>
        <li>
          • royalty-free, which means there are no monetary fees for this
          license
        </li>
      </ul>
      <p>
        <b>Rights</b>
      </p>
      <p>This license allows EarthABC to:</p>
      <ul>
        <li>
          • host, reproduce, distribute, communicate, and use your information —
          for example, to save your content on our systems and make it
          accessible from anywhere you go
        </li>
        <li>
          • publish, publicly perform, or publicly display your content, if
          you’ve made it visible to others
        </li>
        <li>
          • modify and create derivative works based on your content, such as
          reformatting or translating it
        </li>
        <li>• sublicense these rights to:</li>
        <li>• other users to allow the services to work as designed</li>
        <li>
          • our contractors who’ve signed agreements with us that are consistent
          with these terms
        </li>
      </ul>
      <p>
        <b>Purpose</b>
      </p>
      <p>This license is for the limited purpose of:</p>
      <ul>
        <li>
          • operating and improving the services, which means allowing the
          services to work as designed and creating new features and
          functionalities. This includes using automated systems and algorithms
          to analyze your information:
        </li>
        <li>• to recognize patterns in data</li>
        <li>• to customize our services for you</li>
      </ul>
      <p>
        This analysis occurs as the information is sent, received, and when it
        is stored.
      </p>
      <ul>
        <li>
          • using information you’ve shared to promote the services. For
          example, to promote a service or feature, we might quote a review you
          wrote.
        </li>
        <li>
          • developing new technologies and services for EarthABC consistent
          with these terms
        </li>
      </ul>
      <p>
        <b>Duration</b>
      </p>
      <p>
        This license lasts for as long as your information is protected by
        intellectual property rights.
        <br />
        If you remove from our services any information that’s covered by this
        license, then our systems will stop making that information available in
        a reasonable amount of time. There are two exceptions:
      </p>
      <h2>Using EarthABC services</h2>
      <p>
        <b>Your EarthABC Account</b>
        <br />
        You’re responsible for what you do with your EarthABC Account, including
        taking reasonable steps to keep your EarthABC Account secure.
      </p>
      <p>
        Using EarthABC services on behalf of an organization or business
        <br />
        Many organizations such as businesses, non-profits, and schools, take
        advantage of our services. To use our services on behalf of an
        organization:
      </p>
      <ul>
        <li>
          • an authorized representative of that organization must agree to
          these terms
        </li>
        <li>
          • your organization’s administrator may assign an EarthABC Account to
          you. That administrator might require you to follow additional rules
          and may be able to access or disable your EarthABC Account.
        </li>
      </ul>
      <p>
        Service-related communications
        <br />
        To provide you with our services, we sometimes send you service
        announcements and other information.
      </p>
      <p>
        If you choose to give us feedback, such as suggestions to improve our
        services, we may act on your feedback without obligation to you.
      </p>
      <p>
        Information in EarthABC services
        <br />
        Your information
        <br />
        Some of our services give you the opportunity to make your information
        available — for example, you might post a supplier review that you
        wrote, or you might upload a service as a supplier or incentive.
      </p>
      <p>
        If you think your intellectual property rights are being infringed upon,
        you can contact us directly here and we’ll take appropriate action.
      </p>
      <p>
        EarthABC content and information
        <br />
        Some of our services include content and information that belongs to
        EarthABC — for example, many of the roadmaps, features, platform
        capabilities, and visual modeling. We retain any intellectual property
        rights that we have in our content and information. Don’t remove,
        obscure, or alter any of our branding, logos, or legal notices. Contact
        us directly to receive approval to use our branding or logos.
      </p>
      <p>
        Other content
        <br />
        Finally, some of our services give you access to content and information
        that belongs to other people or organizations — for example, a supplier
        or technology description of their own business, or a government
        incentive in The Climate Network. You may not use this content without
        that person or organization’s permission, or as otherwise allowed by
        law. The views expressed in other people or organizations’ content are
        theirs, and don’t necessarily reflect EarthABC's views.
      </p>
      <p>
        Software in EarthABC services
        <br />
        Some of our services share downloadable software offered by other
        organizations. We give you permission to use that software as part of
        the services. All licensing and terms to that software belong to the
        owner of that software and not EarthABC. You should read the separate
        agreement and terms to that software.
      </p>
      <p>
        Some of our services include software that’s offered under open-source
        license terms that we have located for you. All software that you
        download that does not belong to EarthABC will override parts of these
        terms, so please be sure to read those licenses.
      </p>
      <p>
        You may not copy, modify, distribute, sell, or lease any part of our
        services or software.
      </p>
      <p>
        In case of problems or disagreements
        <br />
        Warranty disclaimer
        <br />
        We built our reputation on providing useful, reliable services like The
        Climate Network, and we’re continuously improving our services to meet
        your needs. However, for legal purposes, we offer our services without
        warranties unless explicitly stated.
      </p>
      <p>
        TO THE EXTENT ALLOWED BY APPLICABLE LAW, WE PROVIDE OUR SERVICES “AS IS”
        WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        NON-INFRINGEMENT. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT THE
        CONTENT OR FEATURES OF THE SERVICES, INCLUDING THEIR ACCURACY,
        RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS.
      </p>
      <p>
        Liabilities
        <br />
        For all users
        <br />
        Both the law and these terms try to strike a balance as to what you or
        EarthABC can claim from the other in case of problems. That’s why the
        law allows us to limit certain liabilities — but not others — under
        these terms.
      </p>
      <p>
        These terms only limit our responsibilities as allowed by applicable
        law. These terms don’t limit liability for gross negligence or willful
        misconduct.
      </p>
      <p>To the extent allowed by applicable law:</p>
      <ul>
        <li>• EarthABC is liable only for its br/eaches of these terms</li>
        <li>• EarthABC isn’t liable for:</li>
        <li>
          • loss of profits, revenues, business opportunities, goodwill, or
          anticipated savings
        </li>
        <li>• indirect or consequential losses</li>
        <li>• punitive damages</li>
        <li>
          • EarthABC’s total liability arising out of or relating to these terms
          is limited to the greater of (1) $200 or (2) the fees paid to use the
          relevant services in the 12 months before the dispute
        </li>
      </ul>
      <p>
        For business users and organizations only
        <br />
        If you’re a business user or organization:
      </p>
      <ul>
        <li>
          • To the extent allowed by applicable law, you’ll indemnify EarthABC
          and its directors, officers, employees, and contractors for any
          third-party legal proceedings (including actions by government
          authorities) arising out of or relating to your unlawful use of the
          services or violation of these terms. This indemnity covers any
          liability or expense arising from claims, losses, damages, judgments,
          fines, litigation costs, and legal fees.
        </li>
        <li>
          • If you’re legally exempt from certain responsibilities, including
          indemnification, then those responsibilities don’t apply to you under
          these terms.
        </li>
      </ul>
      <p>
        Taking action in case of problems
        <br />
        Before taking action as described below, we’ll provide you with advance
        notice when reasonably possible, describe the reason for our action, and
        give you an opportunity to fix the problem, unless doing so would:
      </p>
      <ul>
        <li>• cause harm or liability to a user, third party, or EarthABC</li>
        <li>• violate the law or a legal enforcement authority’s order</li>
        <li>• compromise an investigation</li>
        <li>
          • compromise the operation, integrity, or security of our services
        </li>
      </ul>
      <p>
        Removing your content
        <br />
        If any of your information or content (1) breaches these terms, (2)
        violates applicable law, or (3) could harm our users, third parties, or
        EarthABC, then we reserve the right to take down some or all of that
        information or content in accordance with applicable law.
      </p>
      <p>
        Suspending or terminating your access to EarthABC services
        <br />
        EarthABC reserves the right to suspend or terminate your access to the
        services or delete your EarthABC Account if any of these things happen:
      </p>
      <ul>
        <li>• you materially or repeatedly breach these terms</li>
        <li>
          • we’re required to do so to comply with a legal requirement or a
          court order
        </li>
        <li>
          • your conduct causes harm or liability to a user, third party, or
          EarthABC — for example, by hacking, phishing, harassing, spamming,
          misleading others, or scraping content that doesn’t belong to you
        </li>
      </ul>
      <p>
        If you believe your EarthABC Account has been suspended or terminated in
        error, please visit our contact page. Of course, you’re always free to
        stop using our services at any time.
      </p>
      <p>
        Settling disputes, governing law, and courts
        <br />
        Ohio law will govern all disputes arising out of or relating to these
        terms, or any related services, regardless of conflict of laws rules.
        These disputes will be resolved exclusively in the federal or state
        courts of Cuyahoga County, Ohio, USA, and you and EarthABC consent to
        personal jurisdiction in those courts.
      </p>
      <h2>About these terms</h2>
      <p>
        By law, you have certain rights that can’t be limited by a contract like
        these terms of service. These terms are in no way intended to restrict
        those rights.
      </p>
      <p>
        These terms describe the relationship between you and EarthABC. They
        don’t create any legal rights for other people or organizations, even if
        others benefit from that relationship under these terms.
      </p>
      <p>
        We want to make these terms easy to understand, so we’ve used examples
        from our services. But not all services mentioned may be available in
        your country.
      </p>
      <p>
        If it turns out that a particular term is not valid or enforceable, this
        will not affect any other terms.
      </p>
      <p>
        If you don’t follow these terms, and we don’t take action right away,
        that doesn’t mean we’re giving up any rights that we may have, such as
        taking action in the future.
      </p>
      <p>
        We may update these terms (1) to reflect changes in our services or how
        we do business — for example, when we add new services, features,
        technologies, pricing, or benefits (or remove old ones), (2) for legal,
        regulatory, or security reasons, or (3) to prevent abuse or harm.
      </p>
      <p>
        If we materially change these terms, we’ll provide you with reasonable
        advance notice and the opportunity to review the changes, except (1)
        when we launch a new service or feature, or (2) in urgent situations,
        such as preventing ongoing abuse or responding to legal requirements. If
        you don’t agree to the new terms, you should remove your information and
        stop using the services. You can also end your relationship with us at
        any time by closing your EarthABC Account.
      </p>
    </div>
  );
};

import cn from "classnames";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../Icon";
import Subscription from "../Subscription";
import styles from "./Footer.module.sass";

const menu = [
  // {
  //   title: "Company",
  //   url: "/company",
  // },
  {
    title: "Contact",
    url: "/contact-us",
  },
];

const socials = [
  {
    title: "linkedin",
    size: "16",
    url: "https://www.linkedin.com/company/earthabc/?viewAsMember=true",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/EARTHABCINC",
  },
  // {
  //   title: "instagram",
  //   size: "16",
  //   url: "https://www.instagram.com/ui8net/",
  // },
  // {
  //   title: "dribbble",
  //   size: "16",
  //   url: "https://dribbble.com/ui8",
  // },
  // {
  //   title: "behance",
  //   size: "20",
  //   url: "https://www.behance.net/ui8",
  // },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              {/* <Link className={styles.logo} to="/">
                <Image
            className={styles.pic}
            src="/images/eco-system.png"
            srcDark="/images/eco-system.png"
            alt="EarthABC"
          />
                <Icon name={"public"} size={10} />{" "}
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  EarthABC
                </span>
              </Link> */}
              {/* <Theme className={styles.theme} /> */}
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: visible,
              })}
            >
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                discover
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <NavLink
                    className={cn(styles.link, {
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <NavLink className={cn(styles.link)} to={"/terms-of-service"}>
              {"Terms of Service"}
            </NavLink>
            <br />
            <NavLink className={cn(styles.link)} to={"/privacy"}>
              {"Privacy Policy"}
            </NavLink>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Upgrading Humanity, Together.</div>
            <div className={styles.info}>Join EARTH VIP</div>
            <Subscription
              className={styles.subscription}
              placeholder="Email Address"
            />
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2023 EarthABC. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

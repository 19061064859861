import cn from "classnames";
import React from "react";
import Team from "../../screens/Home/Team";
import styles from "./Company.module.sass";

const Vision = () => {
  return (
    <div className={styles.section} style={{ paddingBottom: "1em" }}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Vision</h2>
          <div className={styles.cardContainer}>
            <div className={styles.cardColumn}>
              <div className={styles.card}>
                <img
                  src="/images/strategy.jpg"
                  alt="Strategy"
                  style={{ maxWidth: "17rem" }}
                  className={styles.cardImage}
                />
                <h3 className={styles.cardTitle}>Clean & Renewable Energy</h3>
                <p className={styles.cardParagraph}>
                  Accelerate the adoption of clean and renewable energy
                  strategies.
                </p>
              </div>
            </div>
            <div className={styles.cardColumn}>
              <div className={styles.card}>
                <img
                  src="/images/sustainablecityone.png"
                  alt="Sustainable Communities"
                  style={{ maxWidth: "17rem" }}
                  className={styles.cardImage}
                />
                <h3 className={styles.cardTitle}>Sustainable Communities</h3>
                <p className={styles.cardParagraph}>
                  Leverage technology to create connected and strengthened
                  communities.
                </p>
              </div>
            </div>
            <div className={styles.cardColumn}>
              <div className={styles.card}>
                <img
                  src="/images/globalenergy.jpg"
                  alt="Socioeconomic Scale"
                  style={{ maxWidth: "17rem" }}
                  className={styles.cardImage}
                />
                <h3 className={styles.cardTitle}>Socioeconomic Scale</h3>
                <p className={styles.cardParagraph}>
                  Improve communities by redesigning systems and resources at
                  scale.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Mission = () => {
  return (
    <div className={styles.section} style={{ paddingBottom: "1em" }}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Mission</h2>
          <p className={styles.paragraph}>
            {" "}
            We began with a simple hypothesis - that if we save businesses time
            and money, they will act. That if we align incentives,
            opportunities, and solutions to realize mutual benefit, we will
            engender trust, and from that, achieve profound results. <br />{" "}
            <br /> We are obsessed with realizing a new future for businesses in
            harmony with the planet, people, and nature. While the cacophony
            around the environment and climate is overwhelming and often
            contradictory, our products are designed to efficiently guide people
            so that they know where to go, what to do, and how to do it - for
            everything climate positive.
            <br /> <br /> We believe there must be a better way for everyone,
            especially businesses, to thrive and prosper sustainably. We are
            here to provide innovative solutions to help your business reduce
            its environmental impact, increase efficiency, and save money. We
            are excited to create solutions that will make the journey to the
            future, well, simple. Together, we can arrive faster, and once we
            get there, innovate beyond our limits.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export const Company = () => {
  return (
    <>
      <Vision />
      <Mission />
      <Team />
    </>
  );
};

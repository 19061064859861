import { Route, Routes } from "react-router-dom";
import { Company } from "./components/Company";
import { ContactUs } from "./components/ContactUs";
import Page from "./components/Page";
import { Privacy } from "./components/Privacy";
import { TermsOfService } from "./components/TermsOfService";
import Home from "./screens/Home";
import "./styles/app.sass";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          path="terms-of-service"
          element={
            <Page>
              <TermsOfService />
            </Page>
          }
        />
        <Route
          path="privacy"
          element={
            <Page>
              <Privacy />
            </Page>
          }
        />
        <Route
          path="company"
          element={
            <Page>
              <Company />
            </Page>
          }
        />
        <Route
          path="contact-us"
          element={
            <Page>
              <ContactUs />
            </Page>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;

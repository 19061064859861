import cn from "classnames";
import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../Icon";
import DropdownMenu from "./DropdownMenu";
import styles from "./Header.module.sass";

const navLinks = [
  // {
  //   title: "Company",
  //   url: "/company",
  // },
];

const socials = [
  {
    title: "linkedin",
    size: "16",
    url: "https://www.linkedin.com/company/earthabc/?viewAsMember=true",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/EARTHABCINC",
  },
  // {
  //   title: "instagram",
  //   size: "16",
  //   url: "https://www.instagram.com/ui8net/",
  // },
  // {
  //   title: "dribbble",
  //   size: "16",
  //   url: "https://dribbble.com/ui8",
  // },
  // {
  //   title: "behance",
  //   size: "20",
  //   url: "https://www.behance.net/ui8",
  // },
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  const { pathname } = useLocation();

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          {/* <Image
            className={styles.pic}
            src="/images/eco-system.png"
            srcDark="/images/eco-system.png"
            alt="EarthABC"
          /> */}

          <span style={{ color: "white", fontWeight: "bolder" }}>EarthABC</span>
        </Link>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) =>
              x.content ? (
                <DropdownMenu
                  className={styles.group}
                  item={x}
                  key={index}
                  setValue={setVisibleNav}
                />
              ) : (
                <NavLink
                  className={cn(styles.link, {
                    [styles.active]: pathname === x.url,
                  })}
                  to={x.url}
                  key={index}
                  onClick={() => setVisibleNav(false)}
                >
                  {x.title}
                </NavLink>
              )
            )}
          </nav>
          <div className={styles.details}>
            <div className={styles.socials}>
              {socials.map((x, index) => (
                <a
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Icon name={x.title} size={x.size} />
                </a>
              ))}
            </div>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="https://marketplace.earthabc.com"
            >
              Sign Up
            </Link>
          </div>
        </div>
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="https://marketplace.earthabc.com"
          style={{ marginRight: "1em" }}
        >
          Sign Up
        </Link>

        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="https://marketplace.earthabc.com/"
        >
          Sign In
        </Link>
        <button
          className={cn(styles.burger, {
            [styles.active]: visibleNav,
          })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;

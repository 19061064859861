import cn from "classnames";
import React, { useState } from "react";
import styles from "./Subscription.module.sass";

const ContactUsForm = ({ className }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  return (
    <form className={cn(styles.form, className)} name="contact" netlify>
      <input type="hidden" name="form-name" value="contact" />
      <input
        className={styles.input}
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        name="name"
        placeholder={"Your name"}
        required
      />
      <input
        className={styles.input}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        name="email"
        placeholder={"Your email"}
        required
      />
      <input
        className={styles.input}
        type="text"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        name="subject"
        placeholder={"Subject"}
        required
      />
      <textarea
        className={styles.input}
        type="textarea"
        rows={5}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        name="email"
        placeholder={"Message"}
        required
      />
      <button
        type="submit"
        className={cn("button-small", styles.button)}
        style={{ backgroundColor: "#21a0fe" }}
      >
        Submit
      </button>
    </form>
  );
};

export default ContactUsForm;

import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import styles from "./Book.module.sass";

const items = [
  {
    title: "Financial Incentives",
    color: "#45B26B",
    images: "/images/content/user.svg",
    alt: "user",
    content: "No cost - no search incentives, delivered to you.",
  },
  {
    title: "Suppliers",
    color: "#9757D7",
    images: "/images/content/medal-1.svg",
    alt: "medal",
    content:
      "Remain five-steps ahead of your competition and market readiness.",
  },
  {
    title: "Sustainability Experts",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content: "No more fighting gatekeepers to do the right thing.",
  },
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/company-revenue.png"
              srcSetDark="/images/company-revenue.png"
              src="/images/company-revenue.png"
              srcDark="/images/company-revenue.png"
              alt="Book pic"
            />
          </div>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Get matched</h2>
          <div className={styles.info}>
            EarthABC’s marketplace works for you with proprietary match making.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className={styles.note}>
            <strong className={styles.green}>10% Discount</strong> if you’re
            already using the{" "}
            <strong className={styles.black}>Fitness Pro</strong> on App Store
          </div> */}
          <div className={styles.btns}>
            <Link
              className={cn("button", styles.button)}
              style={{ backgroundColor: "#21a0fe" }}
              to="https://marketplace.earthabc.com"
            >
              <span>Get matched</span>
              <Icon name="arrow-right" size="10" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;

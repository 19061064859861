import cn from "classnames";
import React from "react";
import ContactUsForm from "../ContactUsForm";
import Image from "../Image";
import styles from "./ContactUs.module.sass";

export const ContactUs = () => {
  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <Image
            srcSet="/images/hydroelectric-power@0.png"
            srcSetDark="/images/hydroelectric-power@0.png"
            src="/images/hydroelectric-power@0.png"
            srcDark="/images/hydroelectric-power@0.png"
            alt="Ecosystem"
          />
        </div>
        <div className={cn("stage-small", styles.stage)}>
          Save your time with EarthABC
        </div>
        <h2 className={cn("h2", styles.title)}>Get in touch with EarthABC</h2>
        <div className={styles.text}></div>
        <ContactUsForm className={styles.subscription} />
        {/* <div className={styles.note}>
          By click sign in you, you agree with Stacks <a href="/#">terms</a> and{" "}
          <a href="/#">policy</a>
        </div> */}
      </div>
    </div>
  );
};

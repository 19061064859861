export const Privacy = () => {
  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: 150,
        paddingRight: 150,
        paddingBottom: 20,
      }}
    >
      <div>
        <h1>EARTHABC PRIVACY POLICY</h1>
        <p>
          When you use our services, you’re trusting us with your information.
          We understand this is a big responsibility and work hard to protect
          your information. This Privacy Policy is meant to help you understand
          what information we collect, why we collect it, and how you can
          update, manage, and delete your information.
          <p>Effective January 22, 2023</p>
          <p>
            We build a range of services that help businesses, organizations,
            and people to explore and interact with a network of information in
            new ways. Our services include:
            <ul>
              <li>• EarthABC operated apps and sites</li>
              <li>• Platforms like The Climate Network</li>
              <li>
                • Products that are developed from integrated sources like
                modeling, VR and IR, etc.
              </li>
              <li>
                • Consultancy like leveraging our expertise and network for your
                benefit
              </li>
              <li>
                You can use our services in a variety of ways to manage your
                privacy. For example, you can sign up for an EarthABC Account if
                you want to access and become a user of The Climate Network.
              </li>
              <li>
                <h2>INFORMATION EARTHABC COLLECTS</h2>
                <p>
                  We want you to understand the types of information we collect
                  as you use our services We use the information you provide us
                  to provide better services to all our users — from evaluating
                  basic stuff like your primary location, to more complex things
                  like which solutions you might find most helpful based on the
                  type of business industry you indicate to us.
                </p>
              </li>
              Things you create or provide to us When you create an EarthABC
              Account, you provide us with information that includes your name
              and a password. We store the information you use when creating
              your account and information you update on your account while
              using our services. Information we collect as you use our services
              We collect information about the features you use and information
              you engage with provided to you by EarthABC’s services, which
              helps us understand and update features. The information we
              collect may include browser type and settings, device type and
              settings, operating system, mobile network information including
              carrier name and phone number, and application version number. We
              also collect information about your interaction with our services,
              including IP address, crash reports, system activity, and the
              date, time, and URL of your request. We collect this information
              when an EarthABC service on your device contacts our servers. Your
              activity We collect information about your activity in our
              services. The activity information we collect may include:
            </ul>
          </p>
          <ul>
            <li>• Terms you search for</li>
            <li>• Views and interactions with content</li>
            <li>• Duration of time engaging with our services</li>
            <li>• Behavioral engagement</li>
          </ul>
          <h2>Your location information</h2>
          We collect information about your location when you sign up to use our
          services and provide a business address, which helps us provide
          relevant information based on your general location. Your location can
          be determined with varying degrees of accuracy if you do not include
          your general location when signing up by:
          <ul>
            <li>• GPS and other data</li>
            <li>• IP address</li>
            <li>• Cell towers</li>
          </ul>
          We may also collect information about you, your organization, or your
          business from trusted partners, such as directory services who provide
          us with business information to be displayed on EarthABC’s services,
          marketing partners who provide us with information about potential
          customers of our business services, and security partners who provide
          us with information to protect us. We use various technologies to
          collect and store information. WHY Earthabc COLLECTS DATA We use data
          to build better services We use the information we collect from all
          our services for the following purposes: Provide our services Maintain
          & improve our services Develop new services Provide personalized
          services Measure performance Communicate with you Protect EarthABC,
          our users, and the public We use different technologies to process
          your information for these purposes. We use automated systems that
          analyze your information to provide you with things like customized
          search results, ads, or other features tailored to how you use our
          services. We’ll ask for your consent before using your information for
          a purpose that isn’t covered in this Privacy Policy. Removing &
          deleting your information You can remove and delete your information
          from our services at any time.
          <h2>SHARING YOUR INFORMATION</h2>
          For legal reasons We will share information outside of EarthABC if we
          have a good-faith belief that access, use, preservation, or disclosure
          of the information is reasonably necessary to: • Meet any applicable
          law, regulation, legal process, or enforceable governmental request. •
          Enforce applicable Terms of Service, including investigation of
          potential violations. • Detect, prevent, or otherwise address fraud,
          security, or technical issues. • Protect against harm to the rights,
          property or safety of EarthABC, our users, or the public as required
          or permitted by law. We may share non-identifiable information
          publicly and with our partners. For example, we may share information
          publicly to create awareness for the general use of our services. If
          EarthABC is involved in a merger, acquisition, or sale of assets,
          we’ll continue to ensure the confidentiality of your personal
          information and give affected users notice before personal information
          is transferred or becomes subject to a different privacy policy.
          KEEPING YOUR INFORMATION SECURE We build security into our services to
          protect your information All EarthABC products are built with strong
          security features that continuously protect your information. The
          insights we gain from maintaining our services help us detect and
          automatically block security threats from ever reaching you. And if we
          do detect something risky that we think you should know about, we’ll
          notify you and help guide you through steps to stay better protected.
          DELETING YOUR INFORMATION You can delete it from your EarthABC Account
          at any time RETAINING YOUR INFORMATION We retain the data we collect
          for different periods of time depending on what it is and how we use
          it. When you delete data, we follow a deletion process to make sure
          that your data is safely and completely removed from our public facing
          services. We try to ensure that our services protect information from
          accidental or malicious deletion. Because of this, there may be delays
          between when you delete something and when copies are deleted from our
          active and backup systems. COMPLIANCE & COOPERATION WITH REGULATORS We
          regularly review this Privacy Policy and make sure that we process
          your information in ways that comply with it. Data transfers Your
          information may be processed on servers located outside of the country
          where you live. Data protection laws vary among countries, with some
          providing more protection than others. Regardless of where your
          information is processed, we apply the same protections described in
          this policy. When we receive formal written complaints, we respond by
          contacting the person who made the complaint. We work with the
          appropriate regulatory authorities, including local data protection
          authorities, to resolve any complaints regarding the transfer of your
          data that we cannot resolve with you directly. U.S. state law
          requirements Some U.S. state privacy laws like Ohio’s (OPPA) require
          specific disclosures for consumers and Ohio state residents.
          EarthABC’s services are provided as business-to-business transactions,
          which provides exemptions to our requirements in our privacy policy.
          However, if at any time you would like to OPT out of services you may
          do so by contacting us here. Categories of information we collect
          Identifiers and similar information such as your name and password,
          phone number, and address, as well as unique identifiers tied to the
          browser, application, or device you’re using. Demographic information,
          such as your age, gender and language. Commercial information such as
          your payment information and payment history while using EarthABC
          services. Internet, network, and other activity information such as
          your search terms; views and interactions with content and ads; and
          information about the interaction with our services (like IP address,
          crash reports, and system activity). Geolocation data, such as may be
          determined by GPS, IP address, and other data from sensors on or
          around your device, depending in part on your device and account
          settings. Depending on these settings, this may include precise
          location data. Communications data, such as requests made on our
          services. Professional, employment, and education information, such as
          information you provide while using EarthABC or that is maintained
          through our organization or another organization EarthABC services.
          Other information you create or provide, such as the information you
          provide, upload, or receive (like project information, docs, products,
          services, incentives, etc.). Inferences drawn from the above, like
          your interest categories. Business purposes for which information may
          be used or disclosed Protecting against security threats, abuse, and
          illegal activity; Auditing and measurement; Maintaining our services;
          Research and development; Use of service providers; and Advertising;
          Legal reasons. Parties with whom information may be disclosed Other
          accounts with whom you choose to share your information; Third parties
          with your consent; Service providers; EarthABC Account Users; and Law
          enforcement or other third parties. ABOUT THIS POLICY When this policy
          applies This Privacy Policy applies to all of the services offered by
          EarthABC INC and its affiliates. This Privacy Policy doesn’t apply to
          services that have separate privacy policies that do not incorporate
          this Privacy Policy. This Privacy Policy doesn’t apply to: • The
          information practices of other companies and organizations that
          advertise our services • Services offered by other companies or
          individuals, including products or sites they offer that may include
          EarthABC services to which the policy applies, or products or sites
          displayed to you in search results, or linked from our services
          Changes to this policy We change this Privacy Policy from time to
          time. We will not reduce your rights under this Privacy Policy without
          your explicit consent. We always indicate the date the last changes
          were published. If changes are significant, we’ll provide a more
          prominent notice.
        </p>
      </div>
    </div>
  );
};
